$display-types: (
  none: 'none',
  inline: 'inline',
  block: 'block',
  flex: 'flex',
  grid: 'grid',
);

@each $key, $value in $display-types {
  .d-#{$key} {
    display: #{$value};
  }
}

$positions: (
  static: 'static',
  absolute: 'absolute',
  fixed: 'fixed',
  relative: 'relative',
  sticky: 'sticky',
  initial: 'initial',
  inherit: 'inherit',
);

@each $key, $value in $positions {
  .position-#{$key} {
    position: #{$value};
  }
}

$flex-directions: (11 row: 'row', column: 'column');

@each $key, $value in $flex-directions {
  .flex-#{$key} {
    display: flex;
    flex-direction: #{$value};
  }
}

$flex-modifiers: (
  justify-content: (
    'start': 'flex-start',
    'end': 'flex-end',
    'center': 'center',
    'between': 'space-between',
    'around': 'space-around',
  ),
  align-items: (
    'start': 'flex-start',
    'end': 'flex-end',
    'center': 'center',
    'baseline': 'baseline',
    'stretch': 'stretch',
  ),
);

@each $list, $items in $flex-modifiers {
  @each $key, $value in $items {
    .#{$list}-#{$key} {
      #{$list}: #{$value};
    }
  }
}

$flex-wrap: (
  'wrap': 'wrap',
  'nowrap': 'nowrap',
  'wrap-reverse': 'wrap-reverse',
);

@each $key, $value in $flex-wrap {
  .flex-#{$key} {
    flex-wrap: #{$value};
  }
}
