@use 'sass:color';
@use './styles/variables/colors' as colors;
@use './styles/variables/typography' as typography;
@use './styles/mixins' as mixins;

%button {
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  font-weight: typography.$font-weight-normal;
  line-height: 1.5;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    transition: all 0.2s ease-out;
  }

  $button-variants: (solid, outline);
  $button-sizes: (md, lg);

  @each $button-size in $button-sizes {
    &.size-#{$button-size} {
      @if $button-size == md {
        font-size: 0.875rem;
        padding: 0.438rem 1.313rem;
      }

      @if #{$button-size} == lg {
        font-size: 1rem;
        padding: 0.5rem 1.5rem;
      }
    }
  }

  @each $variant in $button-variants {
    @if $variant == solid {
      &.#{$variant} {
        @each $color-name, $color-value in colors.$brand-colors {
          &-#{$color-name} {
            background-color: $color-value;
            @if $color-name == white {
              color: colors.$dark;
            }
            @if $color-name == light {
              color: colors.$dark;
            }

            @if $color-name == warning {
              color: colors.$dark;
            } @else {
              color: colors.$white;
            }
            border-color: $color-value;

            &:hover {
              @if $variant == solid {
                background-color: color.adjust($color-value, $lightness: -5%);
              }
              @if $variant == outline {
                background-color: $color-value;
                color: colors.$white;
              }
            }
          }
        }
      }
    }

    @if $variant == outline {
      &.#{$variant} {
        @each $color-name, $color-value in colors.$brand-colors {
          &-#{$color-name} {
            background-color: transparent;
            color: $color-value;
            border-color: $color-value;

            &:hover {
              background-color: color.adjust($color-value, $lightness: 40%);
              color: $color-value;
            }
          }
        }
      }
    }
  }
}

@mixin button {
  @extend %button;
}

%link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $primary;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;

  .icon,
  svg {
    width: 17px;
    font-weight: 700;
    scale: 1;
    transition: scale 0.2s ease-in-out;
    transform: rotate(323deg);
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;

    .icon,
    svg {
      scale: 1.5;
      transition: scale 0.2s ease-in-out;
    }
  }
}
