@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:opsz,wght@8..60,400;8..60,500;8..60,600;8..60,700&display=swap');
@import 'styles/modern-normalize.css';

body {
  margin: 0 !important;
  font-family: 'Source Serif 4' !important;
  color: #ffffff;
  background-color: #000000;
}

@font-face {
  font-family: 'Open Sans';
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('https://fonts.googleapis.com/css2?family=Source+Serif+4:opsz,wght@8..60,300;8..60,400;8..60,500;8..60,600;8..60,700&display=swap');
}

/* Defaults */

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

a {
  color: #000000;
  text-decoration: none;
}

img {
  width: 100%;
}
