@import 'styles';
@import 'styles/variables/templates';

.footer {
  padding: 5rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  font-size: 1.25rem;

  @include media-up(md) {
    flex-direction: row;
    gap: 1.5rem;
  }

  .left,
  .center,
  .right {
    display: flex;
  }

  .left {
    flex-direction: column;
    text-align: center;

    @include media-up(md) {
      text-align: left;
    }

    a {
      font-size: 1.25rem;
      font-weight: 600;
      color: $white;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.8;
        transition: opacity 0.2s ease-in-out;
      }
    }

    .heading-wrapper {
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      cursor: default !important;

      @include media-up(md) {
        justify-content: flex-start;
      }

      h4 {
        display: inline-block;
        font-size: 1.25rem;
        font-weight: 400;
        color: $primary;
      }
    }
  }

  .center {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include media-up(md) {
      flex-direction: row;
      gap: 1.5rem;
    }

    a {
      @extend %link;
    }
  }

  .right {
    align-items: flex-end;
    text-align: center;

    @include media-up(md) {
      text-align: right;
    }

    p {
      font-size: 0.875rem;
      color: $gray-4;
      letter-spacing: 0.6px;

      @include media-down(sm) {
        font-size: 0.75rem;
        margin: 0;
      }
    }

    a {
      color: $gray-3;
      opacity: 0.8;
      transition: scale opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }
}

.emoji {
  width: 16px;
  height: 22px;

  .heading-wrapper:hover & {
    transform-origin: bottom center;
    animation-name: wave;
    animation-timing-function: ease-in-out;
    animation-iteration-count: forwards;
    animation-duration: 1.5s;
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
