@import 'styles';

.app-layout {
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;

    @include media-up(sm) {
      max-width: 720px;
      padding: 2rem 1rem;
    }

    @include media-up(lg) {
      max-width: 1140px;
      padding: 2rem 1rem;
    }
  }
}
