$animation-duration: 10s;

$design-x: (0, 30px, 180px, 340px, 500px, 480px, 0);
$design-y: (0, 60px, 280px, 100px, 140px, 135px, 0);

$research-x: (0, -350px, -340px, -420px, -460px, -480px, 0);
$research-y: (0, -10px, -210px, -245px, -245px, -235px, 0);

$concept-x: (0, 490px, 420px, 300px, 260px, 300px, 0);
$concept-y: (0, -330px, -60px, -55px, -65px, -70px, 0);

@mixin animation-template($name, $x-values, $y-values) {
  @keyframes #{$name} {
    @for $i from 1 through length($x-values) {
      $percentage: calc(100% * ($i - 1) / (length($x-values) - 1));
      #{$percentage} {
        transform: translate(nth($x-values, $i), nth($y-values, $i));
      }
    }
  }

  // Correctly target elements with class .name within .line-animation
  .line-animation .#{$name} {
    animation: #{$name} $animation-duration ease-out forwards infinite;
  }
}

// Assuming $design-x and $design-y are lists of x and y values respectively
@include animation-template('design', $design-x, $design-y);
@include animation-template('research', $research-x, $research-y);
@include animation-template('concept', $concept-x, $concept-y);

// Usage within .line-animation
.line-animation {
  @include animation-template(design, $design-x, $design-y);
  @include animation-template(research, $research-x, $research-y);
  @include animation-template(concept, $concept-x, $concept-y);
}
