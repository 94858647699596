@import '../../styles/mixins';
@import '../../styles/variables/colors';

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;
  border-radius: 0.75rem;
  padding: 1.55rem;
  background-color: rgba($white, 0.02);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba($white, 0.04);
    transition: background-color 0.2s ease-in-out;
  }

  @include media-up(lg) {
    flex-direction: row;
  }

  h3 {
    font-weight: 700;
    @include media-up(lg) {
      padding-top: 2.5rem;
    }
  }

  p {
    font-size: 1.125rem;
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  label {
    position: absolute;
    left: 1rem;
    display: flex;
    width: fit-content;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    letter-spacing: 0.6px;
    color: $white;
    margin-top: 1rem;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    cursor: text;

    span {
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out;
    }

    &.focus {
      transform: translateY(-150%);
      background-color: $gray-5;
      color: $white;
      padding: 0 0.25rem;
      font-size: 0.75rem;
      line-height: 1rem;
      opacity: 1;
      transition: all 0.2s ease-in-out;
      cursor: initial;

      span {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }
    }

    &.error {
      color: $red;
      opacity: 1;
      transition: all 0.2s ease-in-out;
      cursor: initial;
    }

    &.loading {
      color: $white;
      opacity: 1;
      transition: all 0.2s ease-in-out;
      cursor: initial;
    }
  }

  input {
    width: 255px;
    border: 1px solid $gray-4;
    background: transparent;
    border-radius: 0.25rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 6px;
    color: $white;
    padding: 0.25rem 1rem;

    &::placeholder {
      color: $white;
      opacity: 0.8;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }

    &.error {
      border: 1px solid $red;
    }

    &.loading {
      border: 1px solid $white;
    }
  }

  img {
    @include media-down(md) {
      max-height: 300px;
      object-fit: cover;
    }

    @include media-up(lg) {
      max-width: 590px;
      object-fit: contain;
    }
  }

  button {
    opacity: 0.85;
    transition: all 0.2s ease-in;
    cursor: pointer;
    color: $black;

    &:hover {
      transition: all 0.2s ease-in;
      opacity: 1;

      svg {
        transform: translateX(5px);
        transition: transform 0.2s ease-in;
      }
    }

    p {
      font-size: 0.875rem;
    }

    svg {
      transition: transform 0.2s ease-in;
    }
  }
}
