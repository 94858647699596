// colors
$gray-1: #f3f3f3;
$gray-2: #f4f4f4;
$gray-3: #cacaca;
$gray-4: #777a7a;
$gray-45: #252525;
$gray-5: #1b1b1b;
$gray-6: #0e0e0e;
$lime: #b3f42a;
$green-light: #aed581;
$green-dark: #7da453;
$blue-vivid: #2ae8f4;
$blue-light: #54e1fc;
$purple: #c285fb;
$orange: #eebc09;
$red: #ee4609;
$white: #ffffff;
$black: #000000;

$colors: (
  gray-1: $gray-1,
  gray-2: $gray-2,
  gray-3: $gray-3,
  gray-4: $gray-4,
  gray-5: $gray-5,
  lime: $lime,
  green-light: $green-light,
  green-dark: $green-dark,
  blue-vivid: $blue-vivid,
  blue-light: $blue-light,
  orange: $orange,
  red: $red,
  white: $white,
  black: $black,
);

@each $key, $value in $colors {
  .color-#{$key} {
    color: #{$value};
  }

  .bg-#{$key} {
    background-color: #{$value};
  }
}

// brand colors
$primary: $purple;
$light: $gray-1;
$dark: $gray-6;
$success: $green-light;
$warning: $orange;
$danger: $red;
$white: $white;
$black: $black;

$brand-colors: (
  primary: $primary,
  light: $light,
  dark: $dark,
  success: $success,
  warning: $warning,
  danger: $danger,
);

@each $key, $value in $brand-colors {
  .text-#{$key} {
    color: #{$value} !important;
  }

  .bg-#{$key} {
    background-color: #{$value} !important;
  }
}

$body-text-color: $white;
$body-bg-color: $black;

$gradient-radial: var(
  --grad,
  radial-gradient(1744.12% 108.14% at 50% 50%, $lime 0%, $blue-vivid 100%)
);

.bg-gradient-radial {
  // background: $gradient-radial;
  // background: radial-gradient(
  //   circle at center,
  //   #a4f100 0%,
  //   rgba(42, 232, 244, 0) 100%
  // );
  background: radial-gradient(
    60% 300% at center,
    $primary 0%,
    rgba(42, 232, 244, 0) 100%
  );
}

.color-gradient-radial {
  background: radial-gradient(
    60% 300% at center,
    $primary 0%,
    rgba(42, 232, 244, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
