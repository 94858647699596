@use 'sass:color';
@import 'styles/variables';

.project {
  border-radius: 0.75rem;
  color: $white;
  background-color: rgba($white, 0.02);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba($white, 0.08);
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
  }

  .read,
  .tags {
    font-family: 'Open Sans', sans-serif;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    font-size: 1rem;

    @include media-up(md) {
      font-size: 1.25rem;
    }
  }

  .arrow {
    transform: translateX(0);
    transition: transform 0.2s ease-in;
  }

  &:hover {
    .arrow {
      transform: translateX(10px);
      transition: transform 0.2s ease-in-out;
    }
  }
}
