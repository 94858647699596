$sizes: (10, 15, 20, 25, 33, 50, 75, 100);

// Spacer-related utility classes

@each $size in $sizes {
  .w-#{$size} {
    width: #{$size}+ '%';

    &.h-#{$size} {
      height: #{$size}+ '%';
    }
  }
}
