@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Serif+4:opsz,wght@8..60,300;8..60,400;8..60,500;8..60,600;8..60,700&display=swap');

@import 'variables/index';
@import 'mixins';
@import './variables/templates';

.box {
  padding: 1.5rem 1.5rem 2rem;
  border-radius: 0.75rem;

  @include media-up(md) {
    padding: 1.5rem 3rem 2.5rem;
  }
}

.rounded {
  border-radius: 0.25rem;
}

.overflow {
  &-hidden,
  &-x-hidden,
  &-y-hidden {
    overflow: hidden;
  }

  &-auto,
  &-x-auto,
  &-y-auto {
    overflow: auto;
  }

  &-scroll,
  &-x-scroll,
  &-y-scroll {
    overflow: scroll;
  }
}

.link {
  @extend %link;
}
