@import './global';
@import './fonts';
@import 'styles/mixins';

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-weights: (
  normal: $font-weight-normal,
  medium: $font-weight-medium,
  semibold: $font-weight-semibold,
  bold: $font-weight-bold,
);

@each $key, $value in $font-weights {
  .font-#{$key} {
    font-weight: #{$value};
  }
}

$h1-font-size: $base-size * 2.625; // 42px
$h2-font-size: $base-size * 2.625; // 42px
$h3-font-size: $base-size * 2; // 32px
$h4-font-size: $base-size * 1.75; // 32px

$headings: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
);

$text-transforms: (none, capitalize, uppercase, lowercase, initial, inherit);

@each $value in $text-transforms {
  .text-#{$value} {
    text-transform: #{$value};
  }
}

p {
  // 22px
  font-size: $base-size * 1.375;
  line-height: 1.5;
  font-family: $font-family-base;
  margin: 0.75rem 0;

  @include media-down(sm) {
    font-size: $base-size * 1.125;
  }
}

h1 {
  line-height: 1.4;
  margin: 1rem 0;
  font-family: $font-family-brand;
  font-size: $h1-font-size;
  font-weight: 700;

  @include media-down(sm) {
    font-size: $h3-font-size;
  }
}

h2 {
  line-height: 1.4;

  font-family: $font-family-brand;
  font-size: $h2-font-size;
  font-weight: 700;

  @include media-down(sm) {
    font-size: $h3-font-size;
  }
}

h3 {
  line-height: 1.4;
  font-family: $font-family-base;
  font-size: $h3-font-size;

  @include media-down(sm) {
    font-size: $h4-font-size;
  }
}
