@import 'styles';

.personal-details {
  gap: 1rem;
  margin: 0 0 2.75rem;
  padding-bottom: 1rem;
  overflow-x: auto;

  h4 {
    font-size: 1.125rem;
  }

  p {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0.5rem 0;
  }

  @include media-up(md) {
    overflow-x: initial;

    p {
      font-size: 1.125rem;
    }
  }
}
