@import 'styles/variables';

.nav-back {
  width: max-content;

  svg {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(-0.5rem);
      transition: transform 0.2s ease-in-out;
    }
  }
}

.header {
  background-color: $body-bg-color;

  h2 {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
  }

  .read-progress {
    width: 100%;
    background-color: $white;

    .read-progress-bar {
      height: 0.25rem;
      background-color: $purple;
    }
  }
}

.project {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include media-up(lg) {
    gap: 5.25rem;
  }

  h2 {
    font-weight: 700;
  }

  p {
    font-size: 1.25rem;
  }

  .hero {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include media-up(lg) {
      flex-direction: row;
    }

    h1 {
      font-size: 2rem;
      font-weight: 700;

      @include media-up(lg) {
        font-size: 3rem;
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      font-family: 'Open Sans', sans-serif;
      font-size: 1rem;
      font-weight: 700;

      @include media-up(lg) {
        font-size: 1.5rem;
      }
    }

    .image-wrapper,
    .content-wrapper {
      @include media-up(lg) {
        width: 50%;
      }
    }

    .image-wrapper {
      display: flex;
      border-radius: 0.75rem;

      img {
        object-fit: cover;
        border-radius: 0.75rem;

        @include media-up(lg) {
          max-width: 570px;
          flex-direction: row;
          align-items: center;
        }
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
    }

    p,
    h1 {
      margin: 0;
    }
  }

  .description {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .content {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
  }

  .text-blocks {
    display: grid;
    grid-auto-flow: row;
    padding: 2rem 0 2.5rem;

    @include media-up(lg) {
      grid-auto-flow: column;
      gap: 9rem;
    }

    .text-block {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      h3 {
        font-size: 1.5rem;
        margin: 0;
      }

      p {
        font-size: 1.25rem;
      }
    }
  }

  .section2 {
    display: grid;
    grid-auto-flow: row;
    gap: 3rem;

    p {
      margin: 0;
    }

    @include media-up(lg) {
      grid-auto-flow: column;
      gap: 9rem;
    }

    .content {
      h3:not(:nth-of-type(2)) {
        margin-bottom: 1.25rem;
        font-size: 1.75rem;
      }

      h3:nth-of-type(2) {
        margin-top: 1.7rem;
        margin-bottom: 1.25rem;
      }

      p {
        margin: 0;
      }
    }
  }

  .avatar {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    * {
      margin: 0;
    }
  }

  .cards {
    display: grid;
    grid-auto-flow: row;
    gap: 1rem;

    @include media-down(md) {
      justify-content: center;
    }

    @include media-up(lg) {
      grid-auto-flow: column;
      gap: 2rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    p {
      margin: 0;
    }

    .card {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      height: 100%;
      max-width: 375px;
      border-radius: 2.5rem;
      padding: 2rem;
      text-align: center;
      background-color: #2e2e2e;
      color: $white;
    }
  }

  li {
    p {
      font-size: 1.125rem;
    }
  }
}
