@import 'styles/';

.bio {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @include media-up(lg) {
    flex-direction: row;
  }

  .featured-image {
    width: 100%;
    max-width: 500px;

    @include media-down(md) {
      margin: 0 auto;
      order: 1;
    }

    @include media-up(lg) {
      max-width: 350px;
    }
  }
}
